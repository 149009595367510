import React from "react";
import {FaUserCircle} from "react-icons/fa";
import './HeaderBar.css';

export const HeaderBar = ({selectedPage, username}) => {
    return <>
        {/*<div className={`main-content ${collapsed ? "expanded" : ""}`}>*/}
        {/* Sección fija de encabezado */}
        <div className="header-bar header d-flex justify-content-between align-items-center p-3">
            <h2 className={"d-flex"}>{selectedPage}</h2>
            <div className="profile-section d-flex align-items-center">
                <FaUserCircle size={30}/>
                <span className="user-name ms-2">{username}</span>
            </div>

            {/*<FaUserCircle className="profile-icon" size={30}/><span className="ms-2">{username}</span>*/}
        </div>
        {/*</div>*/}
    </>
};