import './Menu.css';
import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import {FaCog, FaHome} from "react-icons/fa";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import wlogo from "../../assets/LOGO_NOVA_WHITE-34.svg";
import {useNavigate} from "react-router-dom";
import centersLogo from "../../assets/centros.svg";
import servicesLogo from "../../assets/servicios.svg";
import calendarLogo from "../../assets/calendario-lineas.svg";
import trainersLogo from "../../assets/entrenadores.svg";
import clientsLogo from "../../assets/clientes-lineas.svg";
import statisticsLogo from "../../assets/estadisticas.svg";
import billingLogo from "../../assets/dinero-lineas.svg";

export const Menu = ({collapsed, setCollapsed, selectedPage, setSelectedPage, onMenuItemClick}) => {
    // const [activeItem, setActiveItem] = useState("home");
    const navigate = useNavigate();

    const handleNavClick = (page, route) => {
        setSelectedPage(page);
        navigate(route);
    };

    return (
        <div className="d-flex">
            {/* Sidebar for large screens */}
            <div className={`sidebar ${collapsed ? "collapsed" : ""} d-none d-md-block`}>
                <div className="logo-div d-flex align-items-center justify-content-around"
                     onClick={() => handleNavClick("Dashboard", "/dashboard")}>
                    <img src={wlogo} alt="Logo" className="logo"/>
                </div>
                <button
                    className="toggle-btn btn btn-outline-secondary my-3"
                    onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? <BsChevronRight/> : <BsChevronLeft/>}
                </button>

                <Nav className={`flex-column ${collapsed ? "flex-row" : ""}`}>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Calendar" ? "active" : ""}
                                  onClick={() => handleNavClick("Calendar", "/calendar")}>
                            <img src={calendarLogo} alt="Calendario" className="menu-icon"/> {!collapsed &&
                            <span>Calendario</span>}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Centers" ? "active" : ""}
                                  onClick={() => handleNavClick("Centers", "/centers")}>
                            <img src={centersLogo} alt="Centros" className="menu-icon"/> {!collapsed &&
                            <span>Centros</span>}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Services" ? "active" : ""}
                                  onClick={() => handleNavClick("Services", "/services")}>
                            <img src={servicesLogo} alt="Centros" className="menu-icon"/> {!collapsed &&
                            <span>Servicios</span>}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Trainers" ? "active" : ""}
                                  onClick={() => handleNavClick("Trainers", "/trainers")}>
                            <img src={trainersLogo} alt="Entrenadores" className="menu-icon"/> {!collapsed &&
                            <span>Entrenadores</span>}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Clients" ? "active" : ""}
                                  onClick={() => handleNavClick("Clients", "/clients")}>
                            <img src={clientsLogo} alt="Clients" className="menu-icon"/> {!collapsed &&
                            <span>Clientes</span>}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Statistics" ? "active" : ""}
                                  onClick={() => handleNavClick("Statistics", "/statistics")}>
                            <img src={statisticsLogo} alt="Estadísticas" className="menu-icon"/> {!collapsed &&
                            <span>Estadísticas</span>}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Billing" ? "active" : ""}
                                  onClick={() => handleNavClick("Billing", "/billing")}>
                            <img src={billingLogo} alt="Facturación" className="menu-icon"/> {!collapsed &&
                            <span>Facturación</span>}
                        </Nav.Link>
                    </Nav.Item>

                </Nav>
            </div>

            {/* Bottom navbar for small screens */}
            <Navbar className="d-block d-md-none fixed-bottom bg-light">
                <Nav className="w-100 d-flex justify-content-around">
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Home" ? "active" : ""}
                                  onClick={() => handleNavClick("Home", "/home")}>
                            <FaHome/>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Settings" ? "active" : ""}
                                  onClick={() => handleNavClick("Settings", "/settings")}>
                            <FaCog/>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar>
        </div>
    );
};
