import './App.css';
import React, {useState} from "react";
import {Menu} from "./component/main/Menu";
import CenterList from "./component/center/CenterList";
import {Route, Routes} from "react-router-dom";
import {HeaderBar} from "./component/main/HeaderBar";

function App() {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedPage, setSelectedPage] = useState("Dashboard");

    const userName = "Antonio Rigaud";

    return (
        <div className="app-container d-flex">
            <div className="inner-container">
                <Menu
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                />
                {/*<MainContainer collapsed={collapsed} selectedPage={selectedPage} username={userName}/>*/}

                <div className={`main-content ${collapsed ? "expanded" : ""}`}>
                    <HeaderBar selectedPage={selectedPage} username={userName}/>
                    <Routes>
                        <Route path="/" element={<div>En construcción -> ${selectedPage}</div>}/>
                        <Route path="/calendar" element={<div>En construcción -> {selectedPage}</div>}/>
                        <Route path="/centers" element={<CenterList/>}/>
                        <Route path="/services" element={<div>En construcción -> {selectedPage}</div>}/>
                        <Route path="/trainers" element={<div>En construcción -> {selectedPage}</div>}/>
                        <Route path="/clients" element={<div>En construcción -> {selectedPage}</div>}/>
                        <Route path="/statistics" element={<div>En construcción -> {selectedPage}</div>}/>
                        <Route path="/billing" element={<div>En construcción -> {selectedPage}</div>}/>
                        <Route path="*" element={<div>Página no encontrada</div>}/>
                    </Routes>
                </div>
                </div>
            </div>
    );
}

export default App;
